import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClockIcon } from "../svg";
import "./styles/TimePicker.css";

export default function TimePicker({ time, setTime, secondary }) {
  const [isPickerOpen, setIsPIckerOpen] = useState(false);

  const { t } = useTranslation();

  const [hour, setHour] = useState(null);
  const [minutes, setMinutes] = useState(null);

  const timePickerWrapper = useRef(null);

  const timeSelection = Array.from({ length: 24 }, (_, index) => {
    return index.toString().padStart(2, "0");
  });

  const minutesSelection = ["00", "30"];

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        timePickerWrapper.current &&
        !timePickerWrapper.current.contains(event.target)
      ) {
        setIsPIckerOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (time) {
      const [h, m] = time.split(":");

      setHour(h);
      setMinutes(m);
    }
  }, [time]);

  function openTimePicker() {
    setIsPIckerOpen(true);
  }

  function pickHour(hour) {
    setHour(hour);
  }

  function pickMinutes(minutes) {
    setMinutes(minutes);
  }

  function handleTime() {
    if (hour && minutes) {
      setTime(`${hour}:${minutes}`);
      setTimeout(() => {
        setIsPIckerOpen(false);
      }, 0);
    }
  }

  function clearTime() {
    setHour(null);
    setMinutes(null);
  }

  function pickerDropdown() {
    return (
      <div className="time-picker-dropdown">
        <div className="time-picker-options">
          <div>
            {timeSelection.map((el) => {
              return (
                <p
                  key={`hours-${el}`}
                  onClick={() => pickHour(el)}
                  className={hour === el ? "active" : ""}
                >
                  {el}
                </p>
              );
            })}
          </div>
          <div>
            {minutesSelection.map((el) => {
              return (
                <p
                  key={`minutes-${el}`}
                  onClick={() => pickMinutes(el)}
                  className={minutes === el ? "active" : ""}
                >
                  {el}
                </p>
              );
            })}
          </div>
        </div>
        <div className="time-picker-set">
          <p id="clear" onClick={clearTime}>
            {t("timePicker.ctaClear")}
          </p>
          <p id={`${hour && minutes ? "set" : ""}`} onClick={handleTime}>
            {t("timePicker.ctaSet")}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={timePickerWrapper}
      id="time-picker-container"
      className={`form-control flatpickr time-picker-container ${
        !secondary ? "form-control-lg" : "text-sm-end"
      } ${isPickerOpen ? "focus" : ""}`}
      onClick={openTimePicker}
    >
      <div className="time-picker">
        <div className="time-picker-display">
          <span>{hour ?? "--"}</span>:<span>{minutes ?? "--"}</span>
        </div>
        <ClockIcon />
        {isPickerOpen && pickerDropdown()}
      </div>
    </div>
  );
}

import "./App.css";
import { Outlet } from "react-router-dom";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { createContext, useState } from "react";

export const CarwingContext = createContext();

function App() {
  const [reservationTime, setReservationTime] = useState(null);
  const [reservationCar, setReservationCar] = useState(null);

  return (
    <div className="App">
      <CarwingContext.Provider
        value={{
          reservationTime,
          setReservationTime,
          reservationCar,
          setReservationCar,
        }}
      >
        <Navbar />
        <div>
          <Outlet />
        </div>
        <Footer />
      </CarwingContext.Provider>
    </div>
  );
}

export default App;

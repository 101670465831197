import React, { useContext, useEffect, useState } from "react";

import { getProvision } from "../services/db";
import {
  calculateDayDifference,
  isReturnExceedingTakenTime,
  returnProviderName,
} from "../utils/helper-functions";
import { CURRENCY } from "../constants/constants";
import { init, send } from "../../node_modules/@emailjs/browser/es/index";
import ReservationComplete from "../components/ReservationComplete";
import { CarwingContext } from "../App";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./styles/Confirmation.css";
import AddOns from "../components/AddOns";

export default function Confirmation() {
  const { t } = useTranslation();

  const { reservationTime: reservationTimeC, reservationCar } =
    useContext(CarwingContext);

  const [reservationTime, setReservationTime] = useState(null);

  const navigate = useNavigate();

  const [selectedCar, setSelectedCar] = useState(null);

  const [addonsTotal, setAddonsTotal] = useState(0);
  const [selectedAddons, setSelectedAddons] = useState([]);

  const [carwingFee, setCarwingFee] = useState(0);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [promoCode, setPromoCode] = useState("");

  const [errorRequest, setErrorRequest] = useState(false);
  const [reservationFinished, setReservationFinished] = useState(false);

  useEffect(() => {
    if (reservationCar && reservationTimeC) {
      setSelectedCar(reservationCar);
      setReservationTime(reservationTimeC);
    }

    (async function initProvision() {
      const responseProvision = await getProvision();
      const fee = responseProvision.find((el) => el.Type === "Carwing-fee");

      setCarwingFee(fee);
    })();

    init({
      publicKey: process.env.REACT_APP_PUBLIC_EMAILJS_KEY,
    });
  }, []);

  function calculateTotalToPay() {
    const carPrice = carPriceForSelectedDays();
    const total = carPrice + addonsTotal;

    return total;
  }

  function calculateUpfrontToPay() {
    const total = calculateTotalToPay();

    if (!carwingFee) return 0;

    const upfrontPrice = (carwingFee.Amount / 100) * total;

    return Math.round(upfrontPrice);
  }

  function calculateOnPickupToPay() {
    const total = calculateTotalToPay();
    const upfrontPrice = calculateUpfrontToPay();

    return total - upfrontPrice;
  }

  function completeReservation(e) {
    e.preventDefault();

    const carReservationInfo = {
      id: selectedCar.Id,
      model: selectedCar["Car Model"],
    };

    const addons = [];

    selectedAddons.forEach((addon) => {
      if (addon.Number) {
        addons.push(`${addon.Id}: ${addon.Addon} (${addon.Number})`);
      }
    });

    const person = {
      firstName,
      lastName,
      email,
      phoneNumber,
      promoCode,
    };

    const total = calculateTotalToPay();
    const upfront = calculateUpfrontToPay();
    const onPickup = total - upfront;

    const templateParams = {
      ...carReservationInfo,
      ...person,
      ...reservationTime,
      addons,
      total,
      upfront,
      onPickup,
    };

    const serviceID = process.env.REACT_APP_PUBLIC_EMAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_PUBLIC_EMAILJS_TEMPLATE_ID;

    send(serviceID, templateID, templateParams).then(
      (response) => {
        setReservationFinished(true);
      },
      (error) => {
        setErrorRequest(true);
      },
    );
  }

  function carPriceForSelectedDays() {
    const { pickupDate, returnDate, pickupTime, returnTime } = reservationTime;
    const carDayPrice = selectedCar.Price;

    const daysTaken = calculateDayDifference(pickupDate, returnDate);
    const isExtraDay = isReturnExceedingTakenTime(pickupTime, returnTime);

    const totalDays = (daysTaken === 0 ? 1 : daysTaken) + (isExtraDay ? 1 : 0);
    const totalCarPerDayPrice = totalDays * carDayPrice;

    return totalCarPerDayPrice;
  }

  function clearErrorMessage() {
    setErrorRequest(false);
  }

  function back() {
    navigate("/listing");
  }

  if (!selectedCar) {
    return (
      <div onClick={back} className="confirm-back">
        <p>{t("confirmation.noCar.message")} </p>
        <p>{t("confirmation.noCar.back")} </p>
      </div>
    );
  }

  return (
    <>
      {errorRequest && (
        <div className="fail-popup" onClick={clearErrorMessage}>
          {t("confirmation.requestFailed")}
        </div>
      )}

      {reservationFinished ? (
        <ReservationComplete
          pickupDate={reservationTime.pickupDate}
          returnDate={reservationTime.returnDate}
          firstName={firstName}
          lastName={lastName}
          total={calculateTotalToPay()}
        />
      ) : (
        <section className="pt-4 pt-md-5">
          <div className="container">
            <div className="row mb-5">
              <div className="col-xl-9 mx-auto">
                <p className="btn btn-secondary btn-round" onClick={back}>
                  <i className="fas fa-arrow-left"></i>
                </p>
              </div>
            </div>

            <div className="row g-4">
              <div className="col-xl-9 mx-auto">
                <div className="vstack gap-4 mb-5">
                  <div className="card shadow">
                    <div className="card-header border-bottom p-4">
                      <h1 className="fs-3">
                        {t("confirmation.bookingSummary.title")}
                      </h1>
                      <p className="subtitle mb-0">
                        {t("confirmation.bookingSummary.text")}{" "}
                      </p>
                    </div>

                    <div className="card-body p-4">
                      <div className="row align-items-center g-md-4">
                        <div className="col-md-3">
                          <div className="bg-light rounded-3 mb-sm-4">
                            <img
                              className="object-fit-cover rounded"
                              src={selectedCar.Image}
                              alt={selectedCar["Car Model"]}
                              onError={(e) =>
                                (e.target.src = "fallbackCar.jpg")
                              }
                            />
                          </div>
                        </div>

                        <div className="col-md-9 mt-xs-3 mt-sm-3">
                          <div className="badge bg-secondary text-white mb-2 mt-2">
                            {returnProviderName(selectedCar)}
                          </div>

                          <h5 className="card-title mb-2">
                            {selectedCar["Car Model"]}
                          </h5>

                          <p className="card-title">
                            {" "}
                            {t("confirmation.bookingSummary.car.labelMain")}
                          </p>

                          <ul className="nav nav-divider h6 fw-normal">
                            <li className="nav-item">
                              {selectedCar.Transmission}
                            </li>

                            <li className="nav-item">
                              {selectedCar.Seats}{" "}
                              {t("confirmation.bookingSummary.car.l1")}
                            </li>

                            <li className="nav-item">
                              {selectedCar.Bags}{" "}
                              {t("confirmation.bookingSummary.car.l2")}
                            </li>
                          </ul>
                        </div>

                        <div className="row align-items-center mt-5">
                          <hr />

                          <div className="col-md-6">
                            <div>
                              {t("confirmation.bookingSummary.car.pdl")}{" "}
                              <div>
                                <h6>
                                  {reservationTime.pickupDate}{" "}
                                  {t("confirmation.bookingSummary.car.at")}{" "}
                                  {reservationTime.pickupTime}{" "}
                                </h6>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div>
                              {t("confirmation.bookingSummary.car.rdl")}{" "}
                              <div>
                                <h6>
                                  {reservationTime.returnDate}{" "}
                                  {t("confirmation.bookingSummary.car.at")}{" "}
                                  {reservationTime.returnTime}{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mt">
                          <hr />
                          <div className="form-check form-switch form-check-md d-flex justify-content-between mt-4 mb-4">
                            <label
                              className="form-check-label ps-0 pe-4"
                              htmlFor="checkPrivacy1"
                            >
                              <h6>
                                {" "}
                                {t("confirmation.bookingSummary.car.cdp")}{" "}
                              </h6>
                            </label>

                            <p>
                              {carPriceForSelectedDays()} {CURRENCY}
                            </p>
                          </div>
                          <AddOns
                            setAddonsTotal={setAddonsTotal}
                            setSelectedAddons={setSelectedAddons}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <hr />

                        <label className="form-check-label col-sm-6 align-items-center mt-3 mt-sm-auto">
                          <h6 className="mb-1 ">
                            {" "}
                            {t("confirmation.bookingSummary.car.ttp")}{" "}
                          </h6>
                        </label>

                        <div className="col-sm-6 text-sm-end mt-3 mt-sm-auto">
                          <p className="mb-0">
                            {calculateTotalToPay()} {CURRENCY}
                          </p>
                        </div>

                        <div className="form-check-label col-sm-6 align-items-center mt-3 mt-sm-auto">
                          <h6 className="mb-1">
                            {" "}
                            {t("confirmation.bookingSummary.car.optp")}
                          </h6>
                        </div>

                        <div className="col-sm-6 text-sm-end mt-3 mt-sm-auto">
                          <p className="mb-0">
                            {calculateOnPickupToPay()} {CURRENCY}
                          </p>
                        </div>

                        <hr className="mt-2" />

                        <div className="col-sm-6 align-items-center text-sm mt-3 mt-sm-auto">
                          <h5 className="mb-1 fw-normal">
                            {" "}
                            {t("confirmation.bookingSummary.car.utp")}
                          </h5>
                        </div>

                        <div className="col-sm-6 text-sm-end mt-3 mt-sm-auto">
                          <h4 className="mb-0">
                            {calculateUpfrontToPay()} {CURRENCY}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card shadow">
                  <div className="card-header border-bottom p-4">
                    <h4 className="">
                      {" "}
                      {t(
                        "confirmation.bookingSummary.bookerDetails.title",
                      )}{" "}
                    </h4>
                    <p className="subtitle mb-0">
                      {t("confirmation.bookingSummary.bookerDetails.text")}
                    </p>
                  </div>

                  <div className="card-body p-4">
                    <form
                      className="row g-3 mb-4"
                      onSubmit={completeReservation}
                    >
                      <div className="col-md-6">
                        <label className="form-label">
                          {" "}
                          {t("confirmation.bookingSummary.bookerDetails.fn")}
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder={t(
                            "confirmation.bookingSummary.bookerDetails.fnPlaceholder",
                          )}
                          value={firstName}
                          required
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>

                      <div className="col-md-6">
                        <label className="form-label">
                          {" "}
                          {t(
                            "confirmation.bookingSummary.bookerDetails.ln",
                          )}{" "}
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder={t(
                            "confirmation.bookingSummary.bookerDetails.lnPlaceholder",
                          )}
                          value={lastName}
                          required
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>

                      <div className="col-md-6">
                        <label className="form-label">
                          {" "}
                          {t("confirmation.bookingSummary.bookerDetails.email")}
                        </label>

                        <input
                          type="email"
                          className="form-control"
                          placeholder={t(
                            "confirmation.bookingSummary.bookerDetails.emailPlaceholder",
                          )}
                          value={email}
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="col-md-6">
                        <label className="form-label">
                          {t("confirmation.bookingSummary.bookerDetails.phone")}
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder={t(
                            "confirmation.bookingSummary.bookerDetails.phonePlaceholder",
                          )}
                          value={phoneNumber}
                          required
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </div>

                      <div>
                        <label className="form-label">
                          {t("confirmation.bookingSummary.bookerDetails.promo")}
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder={t(
                            "confirmation.bookingSummary.bookerDetails.promoPlaceholder",
                          )}
                          value={promoCode}
                          onChange={(e) => setPromoCode(e.target.value)}
                        />
                      </div>

                      <div className="col-12 mt-4">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          id="rememberCheck"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="rememberCheck"
                        >
                          {t("confirmation.bookingSummary.bookerDetails.span1")}{" "}
                          <a href="/terms-of-use" target="_blank">
                            {t("confirmation.bookingSummary.bookerDetails.a1")}
                          </a>{" "}
                          {t("confirmation.bookingSummary.bookerDetails.span2")}{" "}
                          <a href="/payment-and-security" target="_blank">
                            {t("confirmation.bookingSummary.bookerDetails.a2")}
                          </a>
                          .
                        </label>
                      </div>

                      <div className="col-12 mt-4">
                        <button className="btn btn-primary mb-0">
                          {t("confirmation.bookingSummary.bookerDetails.cta")}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CarwingContext } from "../App";
import { CURRENCY } from "../constants/constants";
import { getCars } from "../services/db";
import { returnProviderName } from "../utils/helper-functions";
import Loader from "./Loader";
import LoadMore from "./LoadMore";

export default function CarList({ filters }) {
  const displayNumber = 25;

  const { setReservationCar } = useContext(CarwingContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [carsData, setCarsData] = useState([]);
  const [cars, setCars] = useState([]);

  const [page, setPage] = useState(1);

  const [isAllCars, setIsAllCars] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async function initCars() {
      const n = page * displayNumber;
      const responseCars = await getCars(n);

      setCarsData(responseCars);
      setCars(responseCars);

      setIsLoading(false);

      if (cars.length === responseCars.length) setIsAllCars(true);
    })();
  }, [page]);

  useEffect(() => {
    if (filters && carsData.length > 0) {
      setCarsAfterFiltering(carsData, filters);
    } else setCars(carsData);
  }, [filters]);

  function setCarsAfterFiltering(carsData, carFilters) {
    let filteredCars = [...carsData];

    carFilters.forEach((filter) => {
      filteredCars = filterCars(filteredCars, filter);
    });

    setCars(filteredCars);
  }

  function filterCars(carsData, filter) {
    if (!filter.value) return carsData;

    return carsData.filter((car) => car[filter.type] == filter.value);
  }

  function loadMore() {
    setPage(page + 1);
    setIsLoading(true);
  }

  function reserve(car) {
    setReservationCar(car);
    navigate("/confirm");
  }

  return (
    <>
      <div className="container">
        <div className="row g-4">
          {cars.map((car) => {
            return (
              <div key={car.Id} className="col-md-6 col-xl-4">
                <div className="card shadow p-2 pb-0 h-100">
                  <div className="position-absolute top-0 start-0 z-index-1 m-4">
                    <div className="badge bg-secondary text-white">
                      {returnProviderName(car)}
                    </div>
                  </div>

                  <img
                    src={car.Image}
                    className="rounded-2"
                    alt={car["Car Model"]}
                    onError={(e) => (e.target.src = "fallbackCar.jpg")}
                  />

                  <div className="card-body px-3 pb-0">
                    <h5 className="card-title">{car["Car Model"]}</h5>
                    <p className="card-title">{t("carList.labelMain")}</p>

                    <ul className="nav nav-divider mb-2 mb-sm-3">
                      <li className="nav-item">{car.Transmission}</li>

                      <li className="nav-item">
                        {car.Seats} {t("carList.l1")}
                      </li>

                      <li className="nav-item">
                        {car.Bags} {t("carList.l2")}
                      </li>
                    </ul>
                  </div>

                  <div className="card-footer pt-0">
                    <div className="d-sm-flex justify-content-sm-between align-items-center">
                      <div className="d-flex align-items-center">
                        <h5 className="fw-normal mb-0 me-1">
                          {car.Price} {CURRENCY}
                        </h5>

                        <span className="mb-0 me-2">{t("carList.l3")}</span>
                      </div>

                      <div className="mt-2 mt-sm-0 z-index-2">
                        <p
                          className="btn btn-sm btn-primary mb-0 w-100"
                          onClick={() => reserve(car)}
                        >
                          {t("carList.ctaLabel")}
                          <i className="bi bi-arrow-right ms-2"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {isLoading && <Loader />}
          {!isAllCars && !isLoading && <LoadMore loadMore={loadMore} />}
        </div>
      </div>
    </>
  );
}

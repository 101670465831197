import React from "react";
import { useTranslation } from "react-i18next";
import { LogoLightIcon } from "../svg/index";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <>
      <footer className="bg-dark p-3 footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="text-center text-md-start mb-3 mb-md-0">
                <a href="/">
                  <LogoLightIcon />
                </a>
              </div>
            </div>

            <div className="col-md-4">
              <div className="text-body-secondary text-center text-primary-hover">
                {" "}
                {t("footer.copyright")}{" "}
              </div>
            </div>

            <div className="col-md-4">
              <ul className="list-inline mb-0 text-center text-md-end">
                <li className="list-inline-item ms-2">
                  <a href="/terms-of-use"> {t("footer.tou")}</a>
                </li>
                <li className="list-inline-item ms-2">
                  <a href="/payment-and-security"> {t("footer.pas")}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

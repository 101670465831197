const apiToken = process.env.REACT_APP_API_TOKEN;

const BASE_URL = "https://app.nocodb.com/api/v2/tables/";

const options = {
  method: "GET",
  headers: {
    "xc-token": apiToken,
  },
};

export async function getCars(limit) {
  let res = {
    list: [],
  };

  await fetch(
    `${BASE_URL}mwo5rp9633lprg3/records?offset=0&limit=${limit}&where=&viewId=vwuspar3rha9dqdm`,
    options,
  )
    .then((response) => response.json())
    .then((response) => {
      res = { ...res, ...response };
    })
    .catch((err) => (res.error = err));

  return res.list;
}

export async function getAddons() {
  let res = {
    list: [],
  };

  await fetch(
    `${BASE_URL}mbjp1t7d63evjmx/records?offset=0&limit=25&where=&viewId=vwil5sci6cczwxr5`,
    options,
  )
    .then((response) => response.json())
    .then((response) => {
      res = { ...res, ...response };
    })
    .catch((err) => (res.error = err));

  return res.list;
}

export async function getProvision() {
  let res = {
    list: [],
  };

  await fetch(
    `${BASE_URL}muv8jeucimuodnd/records?offset=0&limit=25&where=&viewId=vw992r6eudkzmc27`,
    options,
  )
    .then((response) => response.json())
    .then((response) => {
      res = { ...res, ...response };
    })
    .catch((err) => (res.error = err));

  return res.list;
}

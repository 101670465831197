import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enL from "./locale/en.json";
import bsL from "./locale/bs.json";

i18n.use(initReactI18next).init({
  resources: {
    en: enL,
    bs: bsL,
  },
  lng: "en",
});

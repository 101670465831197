import React from "react";

import { useTranslation } from "react-i18next";

export default function PaymentAndSecurity() {
  const { t } = useTranslation();

  return (
    <section className="pt-4 pt-lg-5">
      <div className="container">
        <div className="row mb-4">
          <div className="col-12 text-center">
            <h1 className="fs-2 mb-0">{t("paymentAndSecurity.title")}</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="vstack gap-3">
              <div className="card p-0 bg-transparent text-white">
                <h5> {t("paymentAndSecurity.t1")}</h5>
                <p>
                  {t("paymentAndSecurity.t1l1")}
                  <br />
                  {t("paymentAndSecurity.t1l2")} <br />
                  {t("paymentAndSecurity.t1l3")} <br />
                  {t("paymentAndSecurity.t1l4")} <br />{" "}
                  {t("paymentAndSecurity.t1l5")} <br />
                  {t("paymentAndSecurity.t1l6")} <br />
                  {t("paymentAndSecurity.t1l7")} <br />
                </p>

                <h5>{t("paymentAndSecurity.t2")}</h5>

                <h6>{t("paymentAndSecurity.t2h1")}</h6>

                <p>{t("paymentAndSecurity.t2p1")}</p>

                <h6> {t("paymentAndSecurity.t2h2")} </h6>

                <p>{t("paymentAndSecurity.t2p2")}</p>

                <h5> {t("paymentAndSecurity.t3")}</h5>

                <p>{t("paymentAndSecurity.t3p1")}</p>

                <h5>{t("paymentAndSecurity.t4")}</h5>

                <p>{t("paymentAndSecurity.t4p1")}</p>

                <h6>{t("paymentAndSecurity.t4h1")} </h6>

                <p>{t("paymentAndSecurity.t4p2")}</p>

                <h6> {t("paymentAndSecurity.t4h2")}</h6>

                <p>{t("paymentAndSecurity.t4p3")}</p>

                <h6> {t("paymentAndSecurity.t4h3")}</h6>

                <p>{t("paymentAndSecurity.t4p4")}</p>

                <h6> {t("paymentAndSecurity.t4h5")} </h6>

                <p>{t("paymentAndSecurity.t4p5")}</p>

                <h6> {t("paymentAndSecurity.t4h6")} </h6>

                <p>{t("paymentAndSecurity.t4p6")}</p>

                <h6> {t("paymentAndSecurity.t4h7")}</h6>

                <p>{t("paymentAndSecurity.t4p7")}</p>

                <h5> {t("paymentAndSecurity.t5")}</h5>

                <p>{t("paymentAndSecurity.t5p1")}</p>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <h6>{t("paymentAndSecurity.t5h1")}</h6>
                  <ul className=" mb-0 mt-3 list-unstyled">
                    <li className="mb-3">
                      {" "}
                      <p>
                        <a target="_blank" href=" https://www.visaeurope.com/">
                          <img
                            src="monri-images/visa-secure.png"
                            className=""
                            alt=""
                          />
                        </a>
                      </p>
                    </li>
                    <li className="mb-3">
                      {" "}
                      <p>
                        <a target="_blank" href=" http://www.mastercard.com/">
                          <img
                            src="monri-images/mastercard.png"
                            className=""
                            alt=""
                          />
                        </a>
                      </p>
                    </li>
                    <li className="mb-3">
                      {" "}
                      <p>
                        <a target="_blank" href=" https://www.dinersclub.com/">
                          <img
                            src="monri-images/diners.png"
                            className=""
                            alt="Diners logo"
                          />
                        </a>
                      </p>
                    </li>
                    <li className="mb-3">
                      {" "}
                      <p>
                        <a target="_blank" href=" http://monri.com/">
                          <img
                            src="monri-images/monri.png"
                            className=""
                            alt=""
                          />
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <h6>{t("paymentAndSecurity.t5h2")}</h6>
                  <ul className=" mb-0 mt-3 list-unstyled">
                    <li className="mb-3">
                      {" "}
                      <p>
                        <a target="_blank" href=" http://www.mastercard.com/">
                          <img
                            src="monri-images/mastercardpay.png"
                            className=""
                            alt=""
                          />
                        </a>
                      </p>
                    </li>
                    <li className="mb-3">
                      {" "}
                      <p>
                        <a
                          target="_blank"
                          href=" https://brand.mastercard.com/brandcenter/more-about-our-brands.html"
                        >
                          <img
                            src="monri-images/maestropay.png"
                            className=""
                            alt=""
                          />
                        </a>
                      </p>
                    </li>
                    <li className="mb-3">
                      {" "}
                      <p>
                        <a target="_blank" href=" https://www.visaeurope.com/">
                          <img
                            src="monri-images/visapay.png"
                            className=""
                            alt="Diners logo"
                          />
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

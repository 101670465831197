import React from "react";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import CarList from "../components/CarList";
import ReservationDate from "../components/ReservationDate";

export default function CarListing() {
  const [carTypeFilter, setCarTypeFilter] = useState("");
  const [transmissionFilter, setTransmissionFilter] = useState("");
  const [bagNumberFilter, setBagNumberFilter] = useState("");
  const [seatNumberFilter, setSeatNumberFilter] = useState("");

  const { t } = useTranslation();

  const [filters, setFilters] = useState(null);

  function clear() {
    document.getElementById("filter-form").reset();

    setCarTypeFilter("");
    setTransmissionFilter("");
    setBagNumberFilter("");
    setSeatNumberFilter("");
  }

  function applyFilter(e) {
    e.preventDefault();

    setFilters([
      {
        type: "Category",
        value: carTypeFilter,
      },
      {
        type: "Transmission",
        value: transmissionFilter,
      },
      {
        type: "Bags",
        value: bagNumberFilter,
      },
      {
        type: "Seats",
        value: seatNumberFilter,
      },
    ]);
  }

  return (
    <>
      <section className="bg-info mb-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ReservationDate secondary={true} />
            </div>
          </div>
        </div>
      </section>

      <section className="pt-0 pb-4">
        <div className="container position-relative">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="btn-check-soft"
                />

                <label
                  className="btn btn-primary-soft btn-primary-check mb-0"
                  htmlFor="btn-check-soft"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFilter"
                  aria-controls="collapseFilter"
                >
                  <i className="bi fa-fe bi-sliders me-2"></i>{" "}
                  {t("filters.ctaShow")}
                </label>
              </div>
            </div>
          </div>

          <div className="collapse" id="collapseFilter">
            <div className="card card-body bg-light p-4 mt-4 z-index-9">
              <form id="filter-form" className="row g-4" onSubmit={applyFilter}>
                <div className="col-md-3 col-lg-3">
                  <div className="form-size-lg form-control-borderless">
                    <label className="form-label">{t("filters.typeL")}</label>

                    <select
                      className="form-select js-choice border-0"
                      onChange={(e) => setCarTypeFilter(e.target.value)}
                    >
                      <option value="">{t("filters.tlv1")}</option>

                      <option>{t("filters.tlv2")}</option>

                      <option>{t("filters.tlv3")}</option>

                      <option>{t("filters.tlv4")}</option>

                      <option>{t("filters.tlv5")}</option>

                      <option>{t("filters.tlv6")}</option>

                      <option>{t("filters.tlv7")}</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-3 col-lg-3">
                  <div className="form-size-lg form-control-borderless">
                    <label className="form-label">
                      {t("filters.transmissionL")}
                    </label>

                    <select
                      className="form-select js-choice border-0"
                      onChange={(e) => setTransmissionFilter(e.target.value)}
                    >
                      <option value="">{t("filters.trlv1")}</option>

                      <option>{t("filters.trlv2")}</option>

                      <option>{t("filters.trlv3")}</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-3 col-lg-3">
                  <div className="form-size-lg form-control-borderless">
                    <label className="form-label"> {t("filters.bagL")}</label>

                    <select
                      className="form-select js-choice border-0"
                      onChange={(e) => setBagNumberFilter(e.target.value)}
                    >
                      <option value="">{t("filters.blv1")}</option>

                      <option>1</option>

                      <option>2</option>

                      <option>3</option>

                      <option>4</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-3 col-lg-3">
                  <div className="form-size-lg form-control-borderless">
                    <label className="form-label">{t("filters.seatL")}</label>

                    <select
                      className="form-select js-choice border-0"
                      onChange={(e) => setSeatNumberFilter(e.target.value)}
                    >
                      <option value="">{t("filters.slv1")}</option>

                      <option>4</option>

                      <option>5</option>

                      <option>6</option>

                      <option>7</option>
                    </select>
                  </div>
                </div>

                <div className="text-end align-items-center">
                  <button
                    type="button"
                    className="btn btn-link p-0 mb-0"
                    onClick={clear}
                  >
                    {t("filters.ctaClear")}
                  </button>

                  <button
                    type="submit"
                    className="btn btn-primary-soft mb-0 ms-3"
                  >
                    {t("filters.ctaApply")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-0">
        <CarList filters={filters} />
      </section>
    </>
  );
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BosnianFlag, UnitedKingdomFlag } from "../svg";

export default function LanguagePicker() {
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const { i18n } = useTranslation();

  function switchLanguage() {
    if (currentLanguage === "en") {
      changeLanguage("bs");
    } else changeLanguage("en");
  }

  function changeLanguage(l) {
    setCurrentLanguage(l);
    i18n.changeLanguage(l);
  }

  function languageFlagSwitch(l) {
    switch (l) {
      case "en":
        return <UnitedKingdomFlag />;
      case "bs":
        return <BosnianFlag />;
      default:
        return <UnitedKingdomFlag />;
    }
  }

  return (
    <div onClick={switchLanguage}>{languageFlagSwitch(currentLanguage)}</div>
  );
}

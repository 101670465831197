import React from "react";
import { useTranslation } from "react-i18next";
import { CURRENCY } from "../constants/constants";

export default function ReservationComplete({
  pickupDate,
  returnDate,
  firstName,
  lastName,
  total,
}) {
  const { t } = useTranslation();

  return (
    <>
      <section className="pt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="card shadow">
                <div className="card-body text-center p-4">
                  <h1 className="card-title fs-3">
                    🎊 {t("reservationComplete.title")} 🎊
                  </h1>
                  <p className="lead mb-3">{t("reservationComplete.text")}</p>

                  <div className="row justify-content-between text-start mb-4">
                    <div className="col-lg-5">
                      <ul className="list-group list-group-borderless">
                        <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                          <span className="mb-0">
                            <i className="bi bi-person fa-fw me-2"></i>{" "}
                            {t("reservationComplete.booker")}
                          </span>
                          <span className="h6 fw-normal mb-0">
                            {" "}
                            {firstName} {lastName}{" "}
                          </span>
                        </li>
                        <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                          <span className="mb-0">
                            <i className="bi bi-currency-dollar fa-fw me-2"></i>
                            {t("reservationComplete.totalPrice")}
                          </span>
                          <span className="h6 fw-normal mb-0">
                            {" "}
                            {total} {CURRENCY}{" "}
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="col-lg-5">
                      <ul className="list-group list-group-borderless">
                        <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                          <span className="mb-0">
                            <i className="bi bi-calendar fa-fw me-2"></i>
                            {t("reservationComplete.pdl")}
                          </span>
                          <span className="h6 fw-normal mb-0">
                            {" "}
                            {pickupDate}{" "}
                          </span>
                        </li>
                        <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                          <span className="mb-0">
                            <i className="bi bi-calendar fa-fw me-2"></i>
                            {t("reservationComplete.rdl")}
                          </span>
                          <span className="h6 fw-normal mb-0">
                            {" "}
                            {returnDate}{" "}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

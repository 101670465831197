import React from "react";
import { useTranslation } from "react-i18next";

import ReservationDate from "../components/ReservationDate";

import "../assets/vendor/font-awesome/css/all.min.css";
import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../assets/vendor/flatpickr/css/flatpickr.min.css";
import "../assets/vendor/choices/css/choices.min.css";
import "../assets/vendor/tiny-slider/tiny-slider.css";
import "../assets/vendor/nouislider/nouislider.css";
import "../assets/css/style.css";

import "../assets/vendor/bootstrap/disto/js/bootstrap.bundle.min.js";
import "../assets/vendor/flatpickr/js/flatpickr.min.js";
import "../assets/vendor/choices/js/choices.min.js";
import "../assets/vendor/tiny-slider/tiny-slider.js";
import "../assets/vendor/nouislider/nouislider.min.js";
import "../assets/js/functions.js";

export default function Landing() {
  const { t } = useTranslation();

  return (
    <>
      <section className="pt-0 pt-lg-5">
        <div className="container">
          <div className="row mx-auto mb-5">
            <div className="col-xl-6 mx-auto text-center">
              <h1>{t("landing.title")}</h1>
              <p className="lead mb-0">{t("landing.subTitle")}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-10 ms-auto position-relative">
              <img
                src="https://images.unsplash.com/photo-1533473359331-0135ef1b58bf?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                className="rounded-3"
                alt="car"
              />

              <div className="col-11 col-sm-10 col-lg-8 col-xl-6 position-lg-middle ms-lg-8 ms-xl-7">
                <ReservationDate />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-0 pt-xl-5">
        <div className="container">
          <div className="row mb-3 mb-sm-4">
            <div className="col-12 text-center">
              <h2> {t("whyCarwing.title")}</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="card card-body shadow p-4 h-100">
                <div className="text-white mb-4">
                  <i className="fa fa-car fa-2xl"></i>
                </div>
                <h5> {t("whyCarwing.c1Title")}</h5>
                <p className="mb-0">{t("whyCarwing.c1Text")}</p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="card card-body shadow p-4 h-100">
                <div className="text-white mb-4">
                  <i className="fa fa-money-bills fa-2xl"></i>
                </div>
                <h5>{t("whyCarwing.c2Title")}</h5>
                <p className="mb-0">{t("whyCarwing.c2Text")}</p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="card card-body shadow p-4 h-100">
                <div className="text-white mb-4">
                  <i className="fa fa-headset fa-2xl"></i>
                </div>
                <h5>{t("whyCarwing.c3Title")}</h5>
                <p className="mb-0">{t("whyCarwing.c3Text")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-0 pt-lg-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-xl-8 mx-auto">
              <h2 className="mb-4 text-center">{t("faq.title")}</h2>
              <div
                className="accordion accordion-icon accordion-bg-light"
                id="accordionFaq"
              >
                <div className="accordion-item mb-3">
                  <h6 className="accordion-header font-base" id="heading-1">
                    <button
                      className="accordion-button fw-bold rounded collapsed pe-5"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-1"
                      aria-expanded="true"
                      aria-controls="collapse-1"
                    >
                      {t("faq.q1")}{" "}
                    </button>
                  </h6>
                  <div
                    id="collapse-1"
                    className="accordion-collapse collapse show"
                    aria-labelledby="heading-1"
                    data-bs-parent="#accordionFaq"
                  >
                    <div className="accordion-body mt-3 pb-0">
                      {t("faq.a1")}{" "}
                    </div>
                  </div>
                </div>

                <div className="accordion-item mb-3">
                  <h6 className="accordion-header font-base" id="heading-2">
                    <button
                      className="accordion-button fw-bold rounded collapsed pe-5"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-2"
                      aria-expanded="false"
                      aria-controls="collapse-2"
                    >
                      {t("faq.q2")}{" "}
                    </button>
                  </h6>
                  <div
                    id="collapse-2"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading-2"
                    data-bs-parent="#accordionFaq"
                  >
                    <div className="accordion-body mt-3 pb-0">
                      {t("faq.a2")}{" "}
                    </div>
                  </div>
                </div>

                <div className="accordion-item mb-3">
                  <h6 className="accordion-header font-base" id="heading-3">
                    <button
                      className="accordion-button fw-bold collapsed rounded pe-5"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-3"
                      aria-expanded="false"
                      aria-controls="collapse-3"
                    >
                      {t("faq.q3")}{" "}
                    </button>
                  </h6>
                  <div
                    id="collapse-3"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading-3"
                    data-bs-parent="#accordionFaq"
                  >
                    <div className="accordion-body mt-3 pb-0">
                      {t("faq.a3")}{" "}
                    </div>
                  </div>
                </div>

                <div className="accordion-item mb-3">
                  <h6 className="accordion-header font-base" id="heading-4">
                    <button
                      className="accordion-button fw-bold collapsed rounded pe-5"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-4"
                      aria-expanded="false"
                      aria-controls="collapse-4"
                    >
                      {t("faq.q4")}{" "}
                    </button>
                  </h6>
                  <div
                    id="collapse-4"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading-4"
                    data-bs-parent="#accordionFaq"
                  >
                    <div className="accordion-body mt-3 pb-0">
                      <p>{t("faq.a4")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

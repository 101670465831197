import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CarwingContext } from "../App";
import {
  calculateDayDifferenceFromToday,
  getFormattedCurrentTime,
  getFormattedDate,
  isChrome,
  setSessionItem,
} from "../utils/helper-functions";
import TimePicker from "./TimePicker";

export default function ReservationDate({ secondary }) {
  const { reservationTime, setReservationTime } = useContext(CarwingContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [minStartDate] = useState(getDatePlusDay(1));
  const [maxStartDate, setMaxStartDate] = useState("");
  const [minReturnDate, setMinReturnDate] = useState(getDatePlusDay(1));

  const [minPickupTime, setMinPickupTime] = useState("00:00");
  const [minReturnTime] = useState("00:00");

  const [pickupDate, setPickupDate] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [pickupTime, setPickupTime] = useState("");
  const [returnTime, setReturnTime] = useState("");

  useEffect(() => {
    if (secondary) {
      if (!reservationTime) navigate("/");

      setPickupDate(returnIfReservationTime("pickupDate"));
      setReturnDate(returnIfReservationTime("returnDate"));
      setPickupTime(returnIfReservationTime("pickupTime"));
      setReturnTime(returnIfReservationTime("returnTime"));
    }
  }, []);

  useEffect(() => {
    if (pickupDate && returnDate) {
      const dayDifference = calculateDayDifferenceFromToday(returnDate);
      if (dayDifference === 1 || dayDifference === 0) {
        setMinPickupTime(getFormattedCurrentTime());
      } else {
        setMinPickupTime("00:00");
      }
    }
  }, [pickupTime, pickupDate, returnDate]);

  function onPickupDateChange(e) {
    e.preventDefault();
    setPickupDate(e.target.value);
    setMinReturnDate(getDatePlusDay(0, e.target.value));
  }

  function onReturnDateChange(e) {
    e.preventDefault();
    setReturnDate(e.target.value);
    setMaxStartDate(getDatePlusDay(0, e.target.value));
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!pickupTime || !returnTime) return;

    setReservationTime({
      pickupDate,
      returnDate,
      pickupTime,
      returnTime,
    });

    navigate("/listing");
  }

  function getDatePlusDay(day, dateInput = new Date()) {
    const date = new Date(dateInput);
    date.setDate(date.getDate() + day);
    return date;
  }

  function returnIfReservationTime(keyName) {
    if (reservationTime) return reservationTime[keyName];
    else return "";
  }

  function updateReservationTime(e) {
    e.preventDefault();

    setSessionItem("reservationTime", {
      pickupDate,
      returnDate,
      pickupTime,
      returnTime,
    });
  }

  function openDatePicker(e) {
    // some code in the imported assets hides the date picker icon on chrome, trigger manually
    if (isChrome()) {
      const datePicker = document.getElementById(e.target.id);
      datePicker.showPicker();
    }
  }

  const reservationFormOne = () => {
    return (
      <div className="card shadow pb-0 mt-n7 mt-sm-n8 mt-lg-0">
        <div className="card-header border-bottom pt-3 pl-3 pr-3 p-sm-4 mb-0">
          <h5 className="card-title mb-0">{t("reservationDate.title")}</h5>
        </div>

        <form
          className="card-body form-control-border p-3 p-sm-4 mt-3"
          onSubmit={handleSubmit}
        >
          <div className="row g-2 g-md-4">
            <div className="col-sm-6">
              <div className="form-fs-lg form-control-transparent">
                <label className="form-label small">
                  {t("reservationDate.pdl")}
                </label>

                <input
                  id="date-picker-pickup"
                  type="date"
                  className="form-control flatpickr"
                  placeholder="Select date"
                  min={getFormattedDate(minStartDate)}
                  max={getFormattedDate(maxStartDate)}
                  required
                  onChange={onPickupDateChange}
                  onClick={openDatePicker}
                />
              </div>
            </div>

            <div className="col-sm-6 text-sm-end">
              <div className="form-fs-lg form-control-transparent">
                <label className="form-label small ms-3 ms-md-0 me-md-3">
                  {t("reservationDate.ptl")}
                </label>
                <TimePicker
                  time={pickupTime}
                  setTime={setPickupTime}
                  secondary={true}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-fs-lg form-control-transparent">
                <label className="form-label small">
                  {" "}
                  {t("reservationDate.rdl")}
                </label>

                <input
                  id="date-picker-return"
                  type="date"
                  className="form-control flatpickr"
                  placeholder="Select date"
                  min={getFormattedDate(minReturnDate)}
                  required
                  onChange={onReturnDateChange}
                  onClick={openDatePicker}
                />
              </div>
            </div>

            <div className="col-sm-6 text-sm-end">
              <div className="form-fs-lg form-control-transparent">
                <label className="form-label small ms-3 ms-md-0 me-md-3">
                  {t("reservationDate.rtl")}
                </label>
                <TimePicker
                  time={returnTime}
                  setTime={setReturnTime}
                  secondary={true}
                />
              </div>
            </div>
          </div>

          <div className="d-grid mt-5">
            <button className="btn btn-lg btn-info mb-0">
              {t("reservationDate.cta")}
            </button>
          </div>
        </form>
      </div>
    );
  };

  const reservationFormTwo = () => {
    return (
      <div className="form-control-bg-light bg-mode border p-4 rounded-3">
        <div className="row g-4"></div>

        <form
          className="row g-4 align-items-center"
          onSubmit={updateReservationTime}
        >
          <div className="col-xl-12">
            <div className="row g-4 mt-1">
              <div className="col-md-6 col-xl-3">
                <label className="form-label">
                  {" "}
                  {t("reservationDate.pdl")}
                </label>

                <input
                  id="date-picker-pickup"
                  type="date"
                  className="form-control form-control-lg flatpickr"
                  placeholder="Select date"
                  value={pickupDate}
                  min={getFormattedDate(minStartDate)}
                  max={getFormattedDate(maxStartDate)}
                  required
                  onChange={onPickupDateChange}
                  onClick={openDatePicker}
                />
              </div>

              <div className="col-md-6 col-xl-3">
                <label className="form-label">
                  {" "}
                  {t("reservationDate.ptl")}
                </label>
                <TimePicker time={pickupTime} setTime={setPickupTime} />
              </div>

              <div className="col-md-6 col-xl-3">
                <label className="form-label">
                  {" "}
                  {t("reservationDate.rdl")}
                </label>

                <input
                  id="date-picker-pickup"
                  type="date"
                  className="form-control form-control-lg flatpickr"
                  placeholder="Select date"
                  value={returnDate}
                  min={getFormattedDate(minReturnDate)}
                  required
                  onChange={onReturnDateChange}
                  onClick={openDatePicker}
                />
              </div>

              <div className="col-md-6 col-xl-3">
                <label className="form-label">
                  {" "}
                  {t("reservationDate.rtl")}
                </label>
                <TimePicker time={returnTime} setTime={setReturnTime} />
              </div>
            </div>
          </div>

          <div className="col-xl-2">
            <button className="btn btn-lg btn-primary-soft w-100 mb-0">
              {t("reservationDate.cta2")}
            </button>
          </div>
        </form>
      </div>
    );
  };

  return <>{secondary ? reservationFormTwo() : reservationFormOne()}</>;
}

import { CARWING_MAIL } from "../constants/constants";

export function navigateTo(to) {
  window.location.href = to;
}

export function setSessionItem(label, item) {
  sessionStorage.setItem(label, JSON.stringify(item));
}

export function getSessionItem(label) {
  const sessionItem = sessionStorage.getItem(label);

  return sessionItem ? JSON.parse(sessionItem) : null;
}

export function setLocalItem(label, item) {
  localStorage.setItem(label, JSON.stringify(item));
}

export function getLocalItem(label) {
  const localItem = localStorage.getItem(label);

  return localItem ? JSON.parse(localItem) : null;
}

export function openLink(link, target = "_blank") {
  window.open(link, target);
}

export function openMail(
  mailtoBody,
  mailtoSubject = "Reservation",
  mailto = CARWING_MAIL,
  mailtoCarbonCopy = ""
) {
  const mailtoLink = `mailto:${mailto}?cc=${mailtoCarbonCopy}&subject=${encodeURIComponent(
    mailtoSubject
  )}&body=${encodeURIComponent(mailtoBody)}`;
  openLink(mailtoLink);
}

export function getFormattedDate(date) {
  if (!date) return "";

  const inputDate = new Date(date);

  const year = inputDate.getFullYear();
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDate.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function getFormattedCurrentTime() {
  const { hours, minutes } = getCurrentTime();

  return `${hours}:${minutes}`;
}

export function getCurrentTime() {
  const date = new Date();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return {
    hours,
    minutes,
  };
}

export function calculateDayDifferenceFromToday(dateEnd) {
  const startDate = new Date();
  const endDate = new Date(dateEnd);

  const msDifference = endDate.getTime() - startDate.getTime();
  const dayDifference = Math.floor(msDifference / (1000 * 60 * 60 * 24));

  return dayDifference;
}

export function calculateDayDifference(dateStart, dateEnd) {
  const startDate = new Date(dateStart);
  const endDate = new Date(dateEnd);

  const msDifference = endDate.getTime() - startDate.getTime();
  const dayDifference = msDifference / (1000 * 60 * 60 * 24);

  return dayDifference;
}

export function isReturnExceedingTakenTime(pickupTime, returnTime) {
  const pTime = pickupTime.split(":");
  const rTime = returnTime.split(":");

  if (Number(rTime[0]) > Number(pTime[0])) {
    return true;
  } else {
    if (
      Number(rTime[0]) === Number(pTime[0]) &&
      Number(rTime[1]) > Number(pTime[1])
    ) {
      return true;
    }

    return false;
  }
}

export function returnProviderName(car) {
  const providers = car.Providers;
  const provider = providers ?? { Provider: "" };

  return provider.Provider;
}

export function isChrome() {
  return (
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
  );
}

import React from "react";
import "./styles/LoadMore.css";

export default function LoadMore({ loadMore }) {
  return (
    <div className="load-more-container">
      <button
        onClick={loadMore}
        className="btn btn-primary-soft btn-primary-check mb-0"
      >
        Load more
      </button>
    </div>
  );
}

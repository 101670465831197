import React from "react";
import { useTranslation } from "react-i18next";

export default function Terms() {
  const { t } = useTranslation();

  return (
    <section className="pt-4 pt-lg-5">
      <div className="container">
        <div className="row mb-4">
          <div className="col-12 text-center">
            <h1 className="fs-2 mb-0">{t("tou.tou")}</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="vstack gap-3">
              <div className="card p-0 bg-transparent flatpickr">
                <p>{t("tou.general_information")}</p>
                <p>
                  {t("tou.address.line1")}
                  <br />
                  {t("tou.address.line2")}
                </p>
                <p>
                  {t("tou.conditions_intro.line1")}
                  <br />
                  {t("tou.conditions_intro.line2")}
                  <br />
                  {t("tou.conditions_intro.line3")}
                </p>
                <p>
                  {t("tou.modification.line1")}
                  <br />
                  {t("tou.modification.line2")}
                </p>
                <p>{t("tou.car_ownership")}</p>
                <p>
                  {t("tou.rental_terms_intro.line1")}
                  <br />
                  {t("tou.rental_terms_intro.line2")}
                  <br />
                  {t("tou.rental_terms_intro.line3")}
                  <br />
                  {t("tou.rental_terms_intro.line4")}
                  <br />
                  {t("tou.rental_terms_intro.line5")}
                  <br />
                  {t("tou.rental_terms_intro.line6")}
                  <br />
                  {t("tou.rental_terms_intro.line7")}
                  <br />
                  {t("tou.rental_terms_intro.line8")}
                  <br />
                  {t("tou.rental_terms_intro.line9")}
                  <br />
                  {t("tou.rental_terms_intro.line10")}
                  <br />
                  {t("tou.rental_terms_intro.line11")}
                  <br />
                  {t("tou.rental_terms_intro.line12")}
                  <br />
                  {t("tou.rental_terms_intro.line13")}
                  <br />
                  {t("tou.rental_terms_intro.line14")}
                </p>

                <p>{t("tou.data_protection")}</p>
                <p>{t("tou.data_protection_content.intro")}</p>
                <ul>
                  <li>{t("tou.data_protection_content.list.0")}</li>
                  <li>{t("tou.data_protection_content.list.1")}</li>
                  <li>
                    <ol>
                      <li>{t("tou.data_protection_content.list.2.item1")}</li>
                      <li>{t("tou.data_protection_content.list.2.item2")}</li>
                    </ol>
                  </li>
                </ul>
                <p>{t("tou.data_protection_content.legal_usage")}</p>

                <p>{t("tou.reservation")}</p>
                <p>{t("tou.reservation_content.intro")}</p>
                <p>{t("tou.reservation_content.last_moment_note")}</p>
                <ul>
                  <li>{t("tou.reservation_content.list.0")}</li>
                  <li>{t("tou.reservation_content.list.1")}</li>
                  <li>{t("tou.reservation_content.list.2")}</li>
                  <li>{t("tou.reservation_content.list.3")}</li>
                  <li>
                    <ol>
                      <li>{t("tou.reservation_content.list.4.item1")}</li>
                      <li>{t("tou.reservation_content.list.4.item2")}</li>
                    </ol>
                  </li>
                  <li>{t("tou.reservation_content.list.5")}</li>
                  <li>{t("tou.reservation_content.list.6")}</li>
                </ul>

                <p>{t("tou.cancel_refund_policy.cancellation")}</p>
                <p>{t("tou.cancel_refund_policy.general_cancellation")}</p>
                <ul>
                  <li>{t("tou.cancel_refund_policy.cancellation_list.0")}</li>
                  <li>{t("tou.cancel_refund_policy.cancellation_list.1")}</li>
                  <li>{t("tou.cancel_refund_policy.cancellation_list.2")}</li>
                  <li>{t("tou.cancel_refund_policy.cancellation_list.3")}</li>
                  <li>{t("tou.cancel_refund_policy.cancellation_list.4")}</li>
                  <li>{t("tou.cancel_refund_policy.cancellation_list.5")}</li>
                  <li>{t("tou.cancel_refund_policy.cancellation_list.6")}</li>
                  <li>{t("tou.cancel_refund_policy.cancellation_list.7")}</li>
                  <li>{t("tou.cancel_refund_policy.cancellation_list.8")}</li>
                </ul>
                <p>{t("tou.cancel_refund_policy.cancellation_note")}</p>
                <p>{t("tou.cancel_refund_policy.modifications")}</p>
                <ul>
                  <li>{t("tou.cancel_refund_policy.modification_list.0")}</li>
                  <li>{t("tou.cancel_refund_policy.modification_list.1")}</li>
                </ul>

                <p>{t("tou.no_show.title")}</p>
                <p>{t("tou.no_show.no_show_conditions.0")}</p>
                <ul>
                  <li>{t("tou.no_show.no_show_conditions.1.a.0")}</li>
                  <li>{t("tou.no_show.no_show_conditions.1.a.1")}</li>
                  <li>{t("tou.no_show.no_show_conditions.1.a.2")}</li>
                  <li>{t("tou.no_show.no_show_conditions.1.a.3")}</li>
                  <li>{t("tou.no_show.no_show_conditions.1.a.4")}</li>
                  <li>{t("tou.no_show.no_show_conditions.1.a.5")}</li>
                  <li>{t("tou.no_show.no_show_conditions.1.a.6")}</li>
                </ul>

                <p>{t("tou.refusal_of_service.title")}</p>
                <p>{t("tou.refusal_of_service.conditions.0")}</p>

                <p>{t("tou.payment.general_provisions.title")}</p>
                <p>{t("tou.payment.general_provisions.content.0")}</p>
                <p>{t("tou.payment.general_provisions.content.1")}</p>
                <p>{t("tou.payment.general_provisions.content.2")}</p>
                <p>{t("tou.payment.general_provisions.content.3")}</p>
                <p>{t("tou.payment.payment_options.title")}</p>
                <p>{t("tou.payment.payment_options.content")}</p>
                <p>{t("tou.payment.payment_methods.title")}</p>
                <p>{t("tou.payment.payment_methods.content.0")}</p>
                <p>{t("tou.payment.payment_methods.content.1")}</p>
                <p>{t("tou.payment.security_deposit.title")}</p>
                <p>{t("tou.payment.security_deposit.content")}</p>
                <p>{t("tou.payment.voucher.title")}</p>
                <p>{t("tou.payment.voucher.content")}</p>
                <p>{t("tou.payment.currency.title")}</p>
                <p>{t("tou.payment.currency.content")}</p>
                <p>{t("tou.payment.rental_extension.title")}</p>
                <p>{t("tou.payment.rental_extension.content")}</p>

                <p>{t("tou.prices.title")}</p>
                <p>{t("tou.prices.general_provisions.0")}</p>
                <p>{t("tou.prices.general_provisions.1")}</p>
                <p>{t("tou.prices.general_provisions.2")}</p>

                <p>{t("tou.prices.included.0")}</p>
                <p>{t("tou.prices.included.1")}</p>
                <p>{t("tou.prices.included.2")}</p>
                <p>{t("tou.prices.included.3")}</p>

                <p>{t("tou.prices.not_included.0")}</p>
                <p>{t("tou.prices.not_included.1")}</p>
                <p>{t("tou.prices.not_included.2")}</p>

                <p>{t("tou.driver_requirements.title")}</p>
                <p>{t("tou.driver_requirements.driver_license.title")}</p>
                <p>{t("tou.driver_requirements.driver_license.content")}</p>
                <p>{t("tou.driver_requirements.driver_age.title")}</p>
                <p>{t("tou.driver_requirements.driver_age.content")}</p>
                <p>{t("tou.driver_requirements.age_restrictions.title")}</p>
                <p>{t("tou.driver_requirements.age_restrictions.content.0")}</p>
                <p>{t("tou.driver_requirements.age_restrictions.content.1")}</p>
                <p>{t("tou.driver_requirements.age_restrictions.content.2")}</p>
                <p>{t("tou.driver_requirements.young_drivers.title")}</p>
                <p>{t("tou.driver_requirements.young_drivers.content")}</p>

                <p>{t("tou.pickup_and_dropoff.title")}</p>
                <p>{t("tou.pickup_and_dropoff.flight_information.title")}</p>
                <p>
                  {t("tou.pickup_and_dropoff.flight_information.content.0")}
                </p>
                <p>
                  {t("tou.pickup_and_dropoff.flight_information.content.1")}
                </p>
                <p>{t("tou.pickup_and_dropoff.refusal_of_service.title")}</p>
                <p>
                  {t("tou.pickup_and_dropoff.refusal_of_service.content.0")}
                </p>
                <p>
                  {t("tou.pickup_and_dropoff.refusal_of_service.content.1")}
                </p>
                <p>
                  {t("tou.pickup_and_dropoff.refusal_of_service.content.2")}
                </p>
                <p>
                  {t("tou.pickup_and_dropoff.refusal_of_service.content.3")}
                </p>
                <p>
                  {t("tou.pickup_and_dropoff.refusal_of_service.content.4")}
                </p>
                <p>
                  {t("tou.pickup_and_dropoff.refusal_of_service.content.5")}
                </p>
                <p>
                  {t("tou.pickup_and_dropoff.refusal_of_service.content.6")}
                </p>
                <p>{t("tou.pickup_and_dropoff.other_provisions.title")}</p>
                <p>{t("tou.pickup_and_dropoff.other_provisions.content.0")}</p>
                <p>{t("tou.pickup_and_dropoff.other_provisions.content.1")}</p>

                <p>{t("tou.full_coverage.title")}</p>
                <p>{t("tou.full_coverage.content.0")}</p>
                <p>{t("tou.full_coverage.content.1")}</p>
                <p>{t("tou.full_coverage.content.2")}</p>
                <p>{t("tou.full_coverage.content.3")}</p>
                <p>{t("tou.full_coverage.content.4")}</p>
                <p>{t("tou.full_coverage.content.5")}</p>
                <p>{t("tou.full_coverage.content.6")}</p>
                <p>{t("tou.full_coverage.content.7")}</p>
                <p>{t("tou.full_coverage.content.8")}</p>
                <p>{t("tou.full_coverage.content.9")}</p>
                <p>{t("tou.full_coverage.content.10")}</p>
                <p>{t("tou.full_coverage.content.11")}</p>
                <p>{t("tou.full_coverage.content.12")}</p>
                <p>{t("tou.full_coverage.content.13")}</p>
                <p>{t("tou.full_coverage.content.14")}</p>
                <p>{t("tou.full_coverage.content.15")}</p>
                <p>{t("tou.full_coverage.content.16")}</p>
                <p>{t("tou.full_coverage.content.17")}</p>
                <p>{t("tou.full_coverage.content.18")}</p>

                <p>{t("tou.jurisdiction.title")}</p>
                <p>{t("tou.jurisdiction.content.0")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

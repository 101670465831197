import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CURRENCY } from "../constants/constants";
import { getAddons } from "../services/db";
import "./styles/AddOns.css";

export default function AddOns({ setAddonsTotal, setSelectedAddons }) {
  const { t, i18n } = useTranslation();
  const [addons, setAddons] = useState([]);

  useEffect(() => {
    (async function initAddons() {
      const responseAddons = await getAddons();

      setAddons(responseAddons);
    })();
  }, []);

  useEffect(() => {
    (function addonsTotal() {
      const t = addons.reduce((acc, currentValue) => {
        const value = currentValue.Price;
        const n = currentValue.Number ?? 0;

        return acc + value * n;
      }, 0);

      setAddonsTotal(t);
    })();

    setSelectedAddons(addons);
  }, [addons, setAddonsTotal, setSelectedAddons]);

  function addAddon(i) {
    calculateSelectedAddonsNumber(i, 1);
  }

  function removeAddon(i) {
    calculateSelectedAddonsNumber(i, -1);
  }

  function returnAddonName(addon) {
    switch (i18n.language) {
      case "en":
        return addon.Addon;
      case "bs":
        return addon["Addon-Bs"];
      default:
        return addon.Addon;
    }
  }

  function calculateSelectedAddonsNumber(i, number) {
    setAddons((addons) => {
      const newAddons = [...addons];
      const n = newAddons[i].Number || 0;

      const canChange = number > 0 ? n < newAddons[i].Max : n > 0;

      if (canChange) {
        newAddons[i] = {
          ...newAddons[i],
          Number: (newAddons[i].Number || 0) + number,
        };
      }

      return newAddons;
    });
  }

  return (
    <>
      <h6> {t("confirmation.addOn.title")}</h6>
      <p> {t("confirmation.addOn.text")} </p>
      {addons.map((addon, index) => {
        return (
          <div
            key={addon.Id}
            className="form-check form-switch form-check-md d-flex justify-content-between mb-4"
          >
            <div className="form-check-label ps-0 pe-4">
              <h6>{returnAddonName(addon)}</h6>

              <p>
                ({addon.Price} {CURRENCY})
              </p>
            </div>

            <div className="addon-cta">
              <div
                className="btn btn-danger btn-round btn-sm"
                onClick={() => removeAddon(index)}
              >
                <i className="fas fa-minus"></i>
              </div>

              <div
                className="btn btn-success btn-round btn-sm"
                onClick={() => addAddon(index)}
              >
                <i className="fas fa-add"></i>
              </div>

              <span>
                {" "}
                {addon.Number ?? 0} / {addon.Max}{" "}
              </span>
            </div>
          </div>
        );
      })}
    </>
  );
}
